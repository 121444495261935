// import styled from 'styled-components';
import React from 'react';
import { AuthenticationForm, 
    InputRow, 
    Input, 
    ButtonRow, 
    BackButton,
    SubmitButton, 
    Message, 
    ErrorMessage,
    } from './AuthStyles';
import axios from 'axios';
import { url } from '../../config';
import {PulseLoader} from 'react-spinners';
import { primaryColor } from '../../Styles';
import styled from 'styled-components';
import { sansSerif } from '../../Styles';

const ResponseMessage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    font-family: ${sansSerif};
    font-size: 0.9em;
`

const ForgotPasswordMessage = styled(ResponseMessage)`
    cursor: pointer;
    text-decoration: underline;
`


const LoginModal = ({back,setIsAuthenticated,setUserId}) => {

    const [error,setError] = React.useState(null);
    const [loading,setLoading] = React.useState(false);
    const [postData,setPostData] = React.useState({
        email:''
        ,password:''
    })
    const [resetMessage,setResetMessage] = React.useState("");
    const [isResetPasswordVisible,setIsResetPasswordVisible] = React.useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPostData({
            ...postData
            ,[name]: value
        });
    }

    const handleLoginUser = async (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            const response = await axios.post(url("login-user"),postData,{withCredentials:true});
            setIsAuthenticated(true);
        } catch (error) {
            setError("Unable to login");
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const handleEnterKey = (e) => {
        if (e.key === 'Enter') {
            handleLoginUser(e);
        }
    }

    const handleInitiatePasswordRecovery = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(url('initiate-password-recovery'),postData);
            setResetMessage("Please check your email for a secure link to reset your password.");
        } catch (error) {
            console.log(error);
            setResetMessage("There was an error sending a password reset link. Please verify your email is correct.");
        }
    }

    const handleForgotPasswordVisible = () => {
        setIsResetPasswordVisible(!isResetPasswordVisible);
    }

    if (!isResetPasswordVisible) {
        return(
            <AuthenticationForm 
                onSubmit={handleLoginUser}
                onKeyDown={handleEnterKey}
            >
                <InputRow>
                    <Input 
                        name = "email"
                        value = {postData.email}
                        onChange={handleInputChange}
                        placeholder='email'
                    />
                </InputRow>
                <InputRow>
                    <Input 
                            name = "password"
                            value = {postData.password}
                            onChange={handleInputChange}
                            placeholder='password'
                            type="password"
                        />
                </InputRow>
                <ButtonRow>
                    <BackButton onClick={back}>Back</BackButton>
                    <SubmitButton type = "submit">Submit</SubmitButton>
                </ButtonRow>
                <ForgotPasswordMessage onClick={handleForgotPasswordVisible}>Forgot password</ForgotPasswordMessage>
                {error && (
                    <ErrorMessage>
                        {error}
                    </ErrorMessage>
                )}
                {loading && (
                    <Message>
                        <PulseLoader color={primaryColor} />
                    </Message>
                )}
            </AuthenticationForm>
        );
    } else {
        return(
            <AuthenticationForm 
                onSubmit={handleInitiatePasswordRecovery}
            >
                <InputRow>
                    <Input 
                        name = "email"
                        value = {postData.email}
                        onChange={handleInputChange}
                        placeholder='email'
                    />
                </InputRow>
                <ButtonRow>
                    <BackButton onClick={handleForgotPasswordVisible}>Back</BackButton>
                    <SubmitButton type = "submit">Submit</SubmitButton>
                </ButtonRow>
                <ResponseMessage>{resetMessage}</ResponseMessage>
            </AuthenticationForm>
        );
    }

}

export default LoginModal;