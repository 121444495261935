 const apiUrl = process.env.REACT_APP_API_URL;

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

 function url(path) {

    // return process.env.REACT_APP_API_URL + path;
    if (process.env.REACT_APP_IS_DEV === '1') {
        return "/" + path;
    } else {
        return process.env.REACT_APP_API_URL + path;
    }
 }

export { apiUrl, url, getCookie };