
const serif = "'Times New Roman', Times, serif";
// const serif = "Cormorant Garamond";
const sansSerif = "Roboto,Helvetica,Arial,sans-serif";

const primaryColor = "#2ed573"
const lightGray = "#dfe4ea"
const darkGray = "#ced6e0"
const fontColor = "#2f3542"
const veryLightGray = "#fafafa"

export {serif,sansSerif,primaryColor,veryLightGray,lightGray,darkGray,fontColor}