import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import JournalPage from './pages/JournalPage';
import AllPages from './pages/AllPages';
import {PasswordRecovery} from './components';
import Authentication from './components/Authentication/Authentication';
import Cookies from 'js-cookie';

const AppRoutes = ({isAuthenticated,setIsAuthenticated,userId,setUserId}) => {

    React.useEffect(() => {
        const csrfCookie = Cookies.get('csrf_access_token');
        if (csrfCookie !== undefined) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      },[]);

      console.log(isAuthenticated);

    return(
        <BrowserRouter>
            <Routes>
                {isAuthenticated && (
                    <>
                        <Route path = "/" element={<JournalPage userId={userId} setIsAuthenticated={setIsAuthenticated}/>} />
                            <Route path = ":pageId" element ={<JournalPage userId={userId} setIsAuthenticated={setIsAuthenticated}/>} />
                        <Route path ="/pages" element={<AllPages userId={userId} setIsAuthenticated={setIsAuthenticated} />} />
                        {/* <Route path = "*" element={<JournalPage userId={userId} setIsAuthenticated={setIsAuthenticated}/>} /> */}
                    </>
                )}
                {!isAuthenticated && (
                    <>
                        <Route path = "/reset-password/:jwt" element={<PasswordRecovery />} />
                        <Route path = "*" element = {<Authentication setIsAuthenticated={setIsAuthenticated} setUserId={setUserId}/>} />
                    </>
                )}
            </Routes>
        </BrowserRouter>
    );
}

export default AppRoutes;