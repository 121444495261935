import styled from 'styled-components';
import React from 'react';
import axios from 'axios';
import {serif,sansSerif, lightGray, darkGray, veryLightGray} from '../Styles';
import { PromptModal, Left } from '../components';
// import {ContextPrompt} from '../components';
import { useDebounce } from 'use-debounce';
// import Typewriter from 'typewriter-effect';
// import {Typewriter} from '../components';
import { useParams } from 'react-router-dom';
import { apiUrl,url } from '../config';
import Cookies from 'js-cookie';


const HomeContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: ${sansSerif};
    background-color: ${veryLightGray};
    position: relative;
`

const PageBody = styled.div`
    z-index: 1;
    height: 100%;
    width: 55%;
    border-width: 1px;
    border-style: solid;
    border-color: ${lightGray};
    border-top: none;
    border-bottom: none;
    box-shadow: 0px 0px 15px ${darkGray};
    background-color: white;
    position: relative;
`

const PageContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
`

const PageTextArea = styled.textarea`
    padding-top: 25px;
    padding-bottom: 10px;
    border: none;
    resize: none;
    outline: none;
`

const Title = styled(PageTextArea)`
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: start;
    border: none;
    border-bottom: 1px solid ${lightGray};
    font-size: 2.5em;
    font-family: ${sansSerif};
    font-weight: bold;
    overflow: hidden;

    &:focus{
        outline: none;
    }
`


const Prompt = styled.div`
    font-size: 1.2em;
    font-family: ${serif};
    font-weight: bold;
    display: flex;
    align-items: start;
    text-align: left;
    border-bottom: 1px solid ${lightGray};
    // font-style: italic;
    padding-top: 25px;
    padding-bottom: 10px;
    cursor: pointer;
`

const TextAreaContainer = styled.div`
    flex: 1;
    border: none;
    // background-color: orange;
    margin-top: 20px;
    margin-bottom: 30px;
    position: relative;
`

const TextArea = styled.textarea`  
    width: 99%;
    height: 99%;
    border: none;
    resize: none;
    outline: none;
    font-family: ${sansSerif};
    font-size: 0.95em;
    line-height: 1.55em;
    // background-color: orange;
    position: relative;
`

const JournalPage = ({userId,setIsAuthenticated}) => {


    const [pageData,setPageData] = React.useState({
        pageId:0,
        titleText:"",
        promptText:"",
        isManualPrompt:0,
        bodyText:"",
        userId:0
    });
    const [lastPageId,setLastPageId] = React.useState(0);
    const [loggedKeystroke,setLoggedKeystroke] = React.useState(false);
    const [isSaved,setIsSaved] = React.useState(true);
    const [modalVisible,setModalVisible] = React.useState(false);
    const [debouncedData] = useDebounce(pageData,3000);
    // const [contextMenuState,setContextMenuState] = React.useState({
    //     visible: false,
    //     x: 0,
    //     y: 0,
    // });
    // const [selectedText,setSelectedText] = React.useState("");

    let { pageId } = useParams();

    const createPage = async () => {
        try {
            
            const response = await axios.post(url("create-page"),pageData,
                {withCredentials:true,
                    headers:{
                        'X-CSRF-TOKEN': Cookies.get("csrf_access_token"),
                    }
                }
                );
            setPageData((prevPageData) => ({
                ...prevPageData,
                pageId: response.data.page_id
            }));
        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    setIsAuthenticated(false);
                }
            }
            console.log(error);
        }
    }

    const loadPage = async (pageId) => {
        try {
    
            const urlString = "load-pages?pageId="+String(pageId);
            const response = await axios.get(url(urlString),
                {withCredentials:true,
                    headers:{
                        'X-CSRF-TOKEN': Cookies.get("csrf_access_token"),
                    }
                });
            
            setPageData((prevPageData) => ({
                ...prevPageData,
                pageId: response.data.pages[0].page_id,
                titleText: response.data.pages[0].title_text,
                promptText: response.data.pages[0].prompt_text,
                bodyText: response.data.pages[0].body_text,
                }));
    
        } catch (error) {
            console.log(error);
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    setIsAuthenticated(false);
                }
            }
        }
    }

    const getLastUpdatedPage = async () => {

        try {
            const urlString = "get-last-updated-page";
            const response = await axios.get(url(urlString),
                {withCredentials:true,
                    headers:{
                        'X-CSRF-TOKEN': Cookies.get("csrf_access_token"),
                    }});
            setLastPageId(response.data.page_id);
        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    setIsAuthenticated(false);
                }
            }
            console.log(error);
        }
    }

    const updatePage = React.useCallback(async (data) => {
        const urlString = "update-page";
        
        try {
            const response = await axios.post(url(urlString),
                data,
                {withCredentials:true,
                    headers:{
                        'X-CSRF-TOKEN': Cookies.get('csrf_access_token'),
                    }});
            setIsSaved(true);
        } catch (error) {
            console.log(error);
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    setIsAuthenticated(false);
                }
            }
        }
    },[setIsSaved]);

    // React.useEffect(() => {
    //     if (userId !== undefined && userId > 0) {
    //         setPageData((prevPageData) => ({
    //             ...prevPageData,
    //             userId:userId
    //         }));
    //     }
    // },[userId]);

    React.useEffect(() => {
        if (pageId !== undefined) {
            loadPage(pageId);
        } else {
            getLastUpdatedPage();
        }
    },[pageId]);
    
    React.useEffect(() => {
        if (lastPageId === -1) {
            createPage();
            setModalVisible(true);
        } else if (lastPageId > 0) {
            loadPage(lastPageId);
        }
    },[lastPageId]);

    const openModal = () => {
        setModalVisible(true);
    }

    const closeModal = React.useCallback(() => {
        setModalVisible(false);
    },[pageData]);

    React.useEffect(() => {
        if (pageData.pageId > 0 && pageData.promptText === "") {
            openModal();
        }
    },[pageData]);

    const handlePageDataChange = React.useCallback((e) => {
        const { name, value } = e.target;
        setPageData((prevPageData) => ({
            ...prevPageData,
            [name]: value
        }));

        if (!loggedKeystroke) {
            setLoggedKeystroke(true);
        }
        setIsSaved(false);
    },[loggedKeystroke,setIsSaved]);

    React.useEffect(() => {
        
        if (debouncedData.pageId > 0 && loggedKeystroke) {
            updatePage(debouncedData);
        }
        
    },[debouncedData]);

    // const handleContextMenu = (e) => {
    //     e.preventDefault();
    //     setContextMenuState({
    //         visible:true,
    //         x: e.clientX,
    //         y: e.clientY,
    //     });
    // }

    // const hideContextMenu = (e) => {
    //     console.log("hiding");
    //     setContextMenuState({
    //         visible: false,
    //     })
    // };
    
    // const handleHighlight = () => {
    //     setSelectedText(window.getSelection().toString());
    // }

    return(
        <HomeContainer>
            {modalVisible && (
                    <PromptModal 
                        pageData = {pageData}
                        setPageData={setPageData}
                        closeModal={closeModal}
                        setIsAuthenticated={setIsAuthenticated}
                    />
                )}
            <PageBody>
                <Left 
                    userId ={userId} 
                    openModal={openModal} 
                    pageId={pageData.pageId} 
                    isSaved={isSaved} 
                    setPageData={setPageData}
                    setIsAuthenticated={setIsAuthenticated}
                />
                <PageContent>
                    <Title 
                        type = "text"
                        name = "titleText"
                        // value = "Lorem Ipsum"
                        value = {pageData.titleText}
                        onChange = {handlePageDataChange}
                        placeholder='Title'
                    />
                    <Prompt onClick={openModal}>
                        {pageData.promptText}
                    </Prompt>
                    <TextAreaContainer>
                        <TextArea 
                            type = "text"
                            name = "bodyText"
                            value = {pageData.bodyText}
                            onChange = {handlePageDataChange}
                            placeholder='Lorem ipsum...'
                            // onMouseUp={handleHighlight}
                            // onClick={hideContextMenu}
                        />
                    </TextAreaContainer>
                </PageContent>
            </PageBody>
            {/* {contextMenuState.visible && (
                <ContextPrompt 
                    x = {contextMenuState.x}
                    y = {contextMenuState.y}
                    highlightedText = {selectedText}
                    bodyText = {pageData.bodyText}
                />
            )} */}
        </HomeContainer>
    );
}

export default JournalPage;

// async function updatePage(data) {
//     const url = apiUrl + "update-page";
//     try {
//         await axios.post(url,data);
//     } catch (error) {
//         console.log(error);
//     }
// }

// const JournalPage = ({userId}) => {

//     const [newClick,setNewClick] = React.useState(false);
//     const [modalVisible,setModalVisible] = React.useState(false);
//     const [loggedKeystroke,setLoggedKeystroke] = React.useState(false);
//     const navigate = useNavigate();

//     let { pageId } = useParams();

//     const [pageData,setPageData] = React.useState({
//         pageId:0
//         ,titleText:''
//         ,promptText:''
//         ,bodyText:''
//         ,userId:userId
//     });

//     const [debouncedData] = useDebounce(pageData,1000);

//     React.useEffect(() => {
        
//         if (debouncedData.pageId > 0 && loggedKeystroke) {
//             updatePage(debouncedData);
//         }
        
//     },[debouncedData]);

//     const loadPage = async (pageId) => {
//         try {

//             const url = apiUrl + "load-pages?pageId="+String(pageId);
//             const response = await axios.get(url);
            
//             setPageData((prevPageData) => ({
//                 ...prevPageData,
//                 pageId: response.data.pages[0].page_id,
//                 titleText: response.data.pages[0].title_text,
//                 promptText: response.data.pages[0].prompt_text,
//                 bodyText: response.data.pages[0].body_text,
//               }));

//             setModalVisible(false);

//         } catch (error) {
//             console.log(error);
//         }

//     }

//     React.useEffect(() => {
//         if (pageData.promptText === "") {
//             setModalVisible(true);
//         }
//     },[pageData]);

//     const createPage = async (pageData) => {
//         try {
//             const url = apiUrl + "create-page";
//             const response = await axios.post(url,pageData);

//             setPageData({
//                 pageId:response.data.page_id
//                 ,titleText:''
//                 ,promptText:''
//                 ,bodyText:''
//                 ,userId:userId
//             })

//             localStorage.setItem('pageId',response.data.page_id);
//             const priorDate = new Date();
//             localStorage.setItem('priorDate',priorDate.toLocaleDateString());
//             navigate("/");
            

//         } catch (error) {
//             console.log(error);
//         }
//     }

//     React.useEffect(() => {
             
//         const localPageId = localStorage.getItem('pageId');
//         const currentDate = new Date();
//         const priorDate = localStorage.getItem('priorDate');

//         if (priorDate === null) {
//             createPage(pageData);
//         } else if (currentDate.toLocaleDateString() !== priorDate) {
//             createPage(pageData);
//         } else if (newClick) {
//             createPage(pageData);
//             setNewClick(false);
//         } else if (pageId !== undefined) {
//             console.log("here");
//             console.log(pageId);
//             loadPage(pageId);
//         } else {
//             loadPage(localPageId);
//         }
            
//     },[newClick,pageId]);

//     const handlePageDataChange = (e) => {
//         const { name, value } = e.target;
//         setPageData((prevPageData) => ({
//             ...prevPageData,
//             [name]: value
//         }));

//         if (!loggedKeystroke) {
//             setLoggedKeystroke(true);
//         }
//     }

//     return(
//         <HomeContainer>
//             {modalVisible && (
//                 <PromptModal 
//                     setPageData={setPageData}
//                     setModalVisible={setModalVisible}
//                 />
//             )}
//             <LeftMargin>
//                 <Left setNewClick={setNewClick}/>
//             </LeftMargin>
//             <PageBody>
//                 <Title 
//                     type = "text"
//                     name = "titleText"
//                     value = {pageData.titleText}
//                     onChange = {handlePageDataChange}
//                     placeholder='Title'
//                 />
//                 <Prompt>
//                 {/* <Typewriter
//                             onInit={(typewriter) => {
//                                 typewriter.typeString(pageData.promptText)
//                                 .start();
//                             }}
//                             options={{
//                                 delay: 10,
//                             }}  
//                         /> */}
                        
//                         {!modalVisible && (
//                             <Typewriter
//                                 onInit={(typewriter) => {
//                                     typewriter.typeString(pageData.promptText)
//                                     .start();
//                                 }}
//                                 options={{
//                                     delay: 10,
//                                 }}  
//                             />
//                         )}  
                    
                        
                        
//                 </Prompt>
//                 <TextArea
//                     type = "text"
//                     name = "bodyText"
//                     value = {pageData.bodyText}
//                     onChange = {handlePageDataChange}
//                     placeholder='Lorem ipsum...'
//                 >
//                 </TextArea>
//             </PageBody>
//             <RightMargin>
//             </RightMargin>
//         </HomeContainer>
        
//     );
// }

// export default JournalPage;