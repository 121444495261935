import React from 'react';

const Typewriter = ({prompt}) => {
  const delay = 25; // Adjust the delay between characters (in milliseconds)
  const [text,setText] = React.useState("");

  React.useEffect(() => {
    
    let currentIndex = 0;
    setText('');
    
    const interval = setInterval(() => {
      if (currentIndex < prompt.length) {
        setText((prevText) => prevText + prompt.charAt(currentIndex));
        currentIndex++;
      } 
    }, delay);
    
    return () => {
        clearInterval(interval);
    };

  }, [prompt,delay]);

  return (
    <div>
      <p>{text}</p>
    </div>
  );
}

export default Typewriter;





// import React from 'react';

// const Typewriter = () => {
//     const tempText = "lorem ipsum dolor sit amet";
//     const [displayText, setDisplayText] = React.useState('');
//     const delay = 25;

//     React.useEffect(() => {
//         let i = 0;
        
//         const interval = setInterval(() => {
//             if (i < tempText.length) {
//                 setDisplayText((prevText) => {return prevText + tempText.charAt(i);});
//                 i++;
//             } else {
//                 clearInterval(interval);
//             }
//         },delay);
    
//         return () => {
//           clearInterval(interval);
//         };
//       },[]);
    
//       return displayText;
    
// }

// export default Typewriter;
// // const Typewriter = (text,speed = 50) => {
// //     const [displayText,setDisplayText] = React.useState('');

// //     React.useEffect(() => {
// //         let i = 0;

// //         const typingInterval = setInterval(() => {
// //             if (i < text.length) {
// //                 setDisplayText(prevText => prevText + text.charAt(i));
// //                 i++;
// //             } else {
// //                 clearInterval(typingInterval);
// //             }
// //         },speed);

// //         return () => {
// //             clearInterval(typingInterval);
// //         };

// //     },[text,speed]);

// //     return displayText;
// // }