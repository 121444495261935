import styled from 'styled-components';
import { serif, sansSerif, primaryColor, veryLightGray, lightGray, darkGray } from '../Styles';
import { BsTrash, BsDownload } from 'react-icons/bs'
import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { url } from '../config';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

const AllPageContainer = styled.div`
    min-height: calc(var(--vh) * 100);
    display: flex;
    flex-direction: row;
    // align-items: start;
    justify-content: center;
    font-family: ${sansSerif};
    background-color: ${veryLightGray};
    `

const PageTitle = styled.div`
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: start;
    font-size: 2em;
    font-weight: bold;
    font-family: ${sansSerif};
`

const PageList = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    // background-color: orange;
    background-color: white;
    z-index: 1;
    box-shadow: 0px 0px 15px ${darkGray};
    padding-left: 25px;
    padding-right: 25px;
`

const NewPageContainer = styled.div`
    // background-color: orange;
    display: flex;
    height: 75px;
    justify-content: start;
    align-items: center;
`

const NewPage = styled.button`
    
    height: 40px;
    border: 1px solid ${lightGray};
    border-radius: 10px;
    background-color: ${lightGray};
    cursor: pointer;
    width: 100px;

    &:hover {
        background-color: ${primaryColor};
    }

`

const ListItem = styled.td`
    border-top: 1px solid ${lightGray};
    min-height: 100px;
    display: flex;
    flex-direction: column;

    &:hover {
        background-color: ${veryLightGray};
        cursor: pointer;
    }

`

const Item = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    font-family: ${sansSerif};
    font-size: 0.85em;
`

const TitleItem = styled(Item)`
    font-size: 1.2em;
    font-weight: bold;
    font-family: ${sansSerif};
    text-align: left;
    padding-top: 10px;
`

const PromptItem = styled(Item)`
    font-style: italic;
    font-family: ${serif};
    font-size: 0.95em;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
`

const DetailedItems = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
`

const OptionsItem = styled(Item)`
    display: flex;
`

const UpdatedItem = styled(Item)`
`

const WordCount = styled(Item)`
    min-width: 110px
`

const Icon = styled.div`
    height: 27px;
    width: 27px;
    border-radius: 50%;
    // background-color: blue;
    border-width: 2px;
    border-style: solid;
    border-color: black;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    font-weight: bold;

    &:hover {
        height: 33px;
        width: 33px;
        font-size: 1.3em;
        border-color: ${primaryColor};
        color: ${primaryColor};
    }
`


const DeletePage = ({pageId,setIsAuthenticated}) => {

    const handleClick = async (e) => {
        try{
            const urlString = "delete-pages?pageId="+String(pageId);
            const response = await axios.delete(url(urlString),
                {withCredentials:true,
                    headers: {
                        'X-CSRF-TOKEN': Cookies.get("csrf_access_token"),
                    }
                });
            // const postData = {pageId:pageId};

            // const response = axios.delete(url,postData);

            // var url = apiUrl + 'delete-pages?pageId='+String(pageId)
            // const response = await axios.delete(url);

            window.location.reload();

        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    setIsAuthenticated(false);
                }
            }
            console.log(error);
        }
    }

    return(
        <>
            <BsTrash onClick={handleClick} />
        </>
    );
}

const AllPages = ({userId,setIsAuthenticated}) => {

    const [data,setData] = React.useState([]);
    const [dataLoaded,setDataLoaded] = React.useState(false);
    const navigate = useNavigate();

    const createPage = async () => {
        try {
            // const url = apiUrl + "create-page";
            const data = {userId:userId};
            // let u = url("create-page");
            // console.log("u:",u);
            const response = await axios.post(url("create-page"),data,{withCredentials:true,
                headers:{
                    'X-CSRF-TOKEN': Cookies.get('csrf_access_token'),
                }
            });
            navigate('/');
            window.location.reload();
        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    setIsAuthenticated(false);
                }
            }
            console.log(error);
        }
    }

    const handleCreatePage = (e) => {
        e.preventDefault();
        createPage();
    }

    const loadPages = async (userId) => {
        try {
            // const url = apiUrl + "load-pages";
            const response = await axios.get(url("load-pages"),
                {withCredentials:true,
                    headers:{
                        'X-CSRF-TOKEN': Cookies.get('csrf_access_token'),
                    }
                });
            setData(response.data.pages);
        } catch (error) {
            console.log(error);
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    setIsAuthenticated(false);
                }
            }
            // if (error.response.status === 401) {
            //     setIsAuthenticated(false);
            // }
            // console.log(data);
        }
        setDataLoaded(true);
    }

    React.useEffect(() => {
        loadPages();
    },[]);

    const handleClick = (pageId) => {
        navigate("/"+String(pageId));
    }

    return(
        <AllPageContainer>
            <PageList>
                <PageTitle>
                    All Journal Entries
                </PageTitle>
                <NewPageContainer>
                    <NewPage onClick={handleCreatePage}>New Page</NewPage>
                </NewPageContainer>
                {data.length === 0 && dataLoaded ? (
                    <p>You haven't created any journal entries yet. <Link to = "/">Create your first.</Link></p>
                ) : (
                    <table>
                        <tbody>
                            {data && (data.map((page,index) => (
                                <tr key = {index}>
                                    <ListItem>
                                        <TitleItem onClick={() => handleClick(page.page_id)}>{page.title_text}</TitleItem>
                                        <PromptItem onClick={() => handleClick(page.page_id)}>{page.prompt_text}</PromptItem>
                                        <DetailedItems>
                                            <OptionsItem>
                                                <Icon><DeletePage pageId={page.page_id}/></Icon>
                                                {/* <Icon><BsDownload /></Icon> */}
                                            </OptionsItem>
                                            <UpdatedItem>{page.last_updated_datetime}</UpdatedItem>
                                            <WordCount>Word count: {page.word_count}</WordCount>
                                        </DetailedItems>
                                    </ListItem>
                                </tr>
                            )))}
                        </tbody>
                    </table>
                )}
            </PageList>
        </AllPageContainer>
    );
}

export default AllPages;