import styled from 'styled-components';
import React from 'react';
import {sansSerif,darkGray,fontColor,primaryColor} from '../../Styles';
import axios from 'axios';
import { url } from '../../config';
import {AiOutlineClose} from 'react-icons/ai';
import { PulseLoader } from 'react-spinners';
import Cookies from 'js-cookie';

const PromptModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgb(255,255,255); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    display: flex;
    align-items: center;
    justify-content: center;
`

const PromptModalContent = styled.div`
    height: 300px;
    width: 600px;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: ${sansSerif};
    color: ${fontColor};
    padding-left: 15px;
    padding-right: 15px;
    
    p {
        font-size: 0.9em;
    }

    position: relative;

`

const Close = styled.div`
    position: absolute;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    cursor: pointer;
`

const PromptTitle = styled.div`
    font-size: 1.2em;
    // font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
`

const Input = styled.input`
    height: 50px;
    border-radius: 25px;
    border: 1px solid ${darkGray};
    padding-left: 25px;
    font-size: 1em;
    font-family: ${sansSerif};
    color: ${fontColor};
`

const InputArea = styled.textarea`
    min-height: 100px;
    resize: none;
    border-radius: 10px;
    border: 1px solid ${darkGray};
    font-size: 1em;
    font-family: ${sansSerif};
    color: ${fontColor};
    padding: 10px;
`

const SubtitleContainer = styled.div`
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const MessageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    font-size: 0.9em;
    color: ${darkGray};
    &:hover {
        cursor: pointer;
    }
`


const PromptModal = ({pageData,setPageData,closeModal,setIsAuthenticated}) => {

    const [inputData,setInputData] = React.useState({
        inputValue:""
    });
    const [displayMessage,setDisplayMessage] = React.useState(0);
    const [isManualInput,setIsManualInput] = React.useState(false);

    const getPrompt = React.useCallback(async () => {
        
        if (!isManualInput) {
            try {
                const response = await axios.post(url("get-prompt"),inputData,{withCredentials:true,
                    headers:{
                        'X-CSRF-TOKEN': Cookies.get('csrf_access_token'),
                    }});
                setPageData((prevPageData) => ({...prevPageData,promptText:response.data.message,isManualPrompt:0}));
            } catch (error) {
                if (error.response !== undefined) {
                    if (error.response.status === 401) {
                        setIsAuthenticated(false);
                    }
                }
                console.log(error);
            }
        } else {
            setPageData((prevPageData) => ({...prevPageData,promptText:inputData.inputValue,isManualPrompt:1}));
        }
        closeModal();
    },[inputData,isManualInput,setPageData,closeModal]);

    const updatePage = React.useCallback(async () => {
    
        try {
            await axios.post(url("update-page"),pageData,{withCredentials:true,
                headers:{
                    'X-CSRF-TOKEN': Cookies.get('csrf_access_token'),
                }});
        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    setIsAuthenticated(false);
                }
            }
            console.log(error);
        }
    },[pageData,setIsAuthenticated]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputData((prevInputData) => ({...prevInputData,[name]:value}));
    } 

    const setDisplayMessage1 = () => {
        setDisplayMessage(1);
        setIsManualInput(true);
        setInputData("");
    }

    const setDisplayMessage0 = () => {
        setDisplayMessage(0);
        setIsManualInput(false);
        setInputData("");
    }

    const handleEnterKey = React.useCallback((e) => {
        if (e.key === 'Enter') {
            setDisplayMessage(2);
            getPrompt();
            updatePage();
        }
    },[getPrompt]);

    return(
        <PromptModalContainer>
            <PromptModalContent>
                <Close onClick={closeModal}><AiOutlineClose /></Close>
                <PromptTitle>
                    What would you like to focus on today?
                </PromptTitle>
                {!isManualInput && (<Input 
                    type = "text"
                    name = "inputValue"
                    value = {inputData.inputValue}
                    onChange = {handleInputChange}
                    onKeyDown = {handleEnterKey}
                    placeholder='Type anything - AI will generate a prompt'
                />)}
                {isManualInput && (
                    <InputArea 
                        type = "text"
                        name = "inputValue"
                        value = {inputData.inputValue}
                        onChange = {handleInputChange}
                        onKeyDown = {handleEnterKey}
                        placeholder='Enter your own prompt'
                    />
                )}
                <SubtitleContainer>
                    <>
                        {displayMessage === 0 && (
                            <MessageContainer onClick={setDisplayMessage1}>Let me enter my own prompt</MessageContainer>
                        )}
                        {displayMessage === 1 && (
                            <MessageContainer onClick={setDisplayMessage0}>Generate prompt with AI</MessageContainer>
                        )}
                        {displayMessage === 2 && (
                            <PulseLoader color={primaryColor} />
                        )}       
                    </>
                </SubtitleContainer>
                
                
            </PromptModalContent>
        </PromptModalContainer>
    );
}

export default PromptModal;