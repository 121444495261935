import React from 'react';
import { 
    AuthenticationContainer,
    NameContainer,
    ImageContainer,
    ActionsContainer,
    LoginButton,
    SignupButton,
    Image,
    AuthenticationActions,
    QuoteContainer,
    BigQuote,
    Author,
    SubQuote
} from './AuthStyles';
import LoginModal from './Login';
import SignupModal from './Signup';
import logo512 from '../../assets/logo512.png';


const Authentication = ({setIsAuthenticated,setUserId}) => {
    const [isAuthVisible,setIsAuthVisible] = React.useState(true);
    const [isSignupVisible,setIsSignupVisible] = React.useState(false);
    const [isLoginVisible,setIsLoginVisible] = React.useState(false);

    const handleAuthVisible = () => {
        setIsAuthVisible(true);
        setIsSignupVisible(false);
        setIsLoginVisible(false);
    }

    const handleSignupVisible = () => {
        setIsAuthVisible(false);
        setIsSignupVisible(true);
        setIsLoginVisible(false);
    }

    const handleLoginVisible = () => {
        setIsAuthVisible(false);
        setIsSignupVisible(false);
        setIsLoginVisible(true);
    }

    return(
        <AuthenticationContainer>
            <AuthenticationActions>
                <NameContainer>Cogito Journal</NameContainer>
                <ImageContainer>
                    <Image src={logo512} />
                </ImageContainer>
                <ActionsContainer>
                    {isAuthVisible && (
                        <>
                            <LoginButton onClick={handleLoginVisible}>Login</LoginButton>
                            <SignupButton onClick={handleSignupVisible}>Signup</SignupButton>
                        </>
                    )}
                    {isLoginVisible && (
                        <LoginModal 
                            back = {handleAuthVisible}
                            setIsAuthenticated = {setIsAuthenticated}
                            setUserId = {setUserId}
                        />
                    )}
                    {isSignupVisible && (
                        <SignupModal 
                            back = {handleAuthVisible}
                            setIsAuthenticated = {setIsAuthenticated}
                            setUserId = {setUserId}
                        />
                    )}
                </ActionsContainer>
            </AuthenticationActions>
            <QuoteContainer>
                <BigQuote>"I write to know what I think."</BigQuote>
                <Author>- Joan Didion</Author>
                <SubQuote>May you learn a bit more about what you think today.</SubQuote>
            </QuoteContainer>
        </AuthenticationContainer>
    );
}
export default Authentication;