import styled from 'styled-components';
import {BsPlusCircle,BsFiles,BsEraser,BsHourglass,BsCheckLg} from 'react-icons/bs';
import { primaryColor } from '../../Styles';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { url } from '../../config';
import Cookies from 'js-cookie';
 
const IconContainer = styled.div`
    // background-color: green;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    position: absolute;
    top: 0;
    left: -50px;
    width: 50px;
`

const Icon = styled.div`
    height: 27px;
    width: 27px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: black;
    color: black;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    font-weight: bold;

    &:hover {
        height: 33px;
        width: 33px;
        font-size: 1.3em;
        border-color: ${primaryColor};
        color: ${primaryColor};
        cursor: pointer;
    }

`

const Left = ({userId,pageId,openModal,isSaved,setPageData,setIsAuthenticated}) => {

    const navigate = useNavigate();
    const handlePagesClick = () => {
        navigate('/pages');
    }

    const createPage = async () => {
        try {
            // const url = apiUrl + "create-page";
            const data = {userId:userId};
            // let u = url("create-page");
            // console.log("u:",u);
            const response = await axios.post(url("create-page"),data,{withCredentials:true,
                headers:{
                    'X-CSRF-TOKEN': Cookies.get('csrf_access_token'),
                }
            });
            navigate('/');
            window.location.reload();
        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    setIsAuthenticated(false);
                }
            }
            console.log(error);
        }
    }

    const deletePage = () => {
        setPageData((prevPageData) => ({
            ...prevPageData,
            titleText: "",
            promptText: "",
            bodyText: "",
            }));
        openModal();
    }

    return(
        <IconContainer>
            <Icon title = "New Journal Page" onClick={createPage}><BsPlusCircle /></Icon>
            <Icon title = "All Pages" onClick={handlePagesClick}><BsFiles /></Icon>
            {/* <Icon title = "Edit" onClick={openModal}><BsPencil /></Icon> */}
            <Icon title = "Delete" onClick={deletePage}><BsEraser /></Icon>
            <Icon title = "Is Saved">
                {isSaved && (<BsCheckLg />)}
                {!isSaved && (<BsHourglass />)}
            </Icon>
        </IconContainer>);
}

export default Left;