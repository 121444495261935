import React, { useRef } from 'react';
import styled from 'styled-components';
import { darkGray, veryLightGray } from '../../Styles';
import Cookies from 'js-cookie';

const MenuContainer = styled.div`
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: end;
    background-color: ${props => props.$menuOpen ? darkGray : 'none'};
`

const MenuButton = styled.div`
    font-size: 2em;
    border: none;
    padding: 10px;
    // background-color: ${props => props.$menuOpen ? darkGray : veryLightGray};
    background-color: none;
    color: ${props => props.$menuOpen ? darkGray : 'black'};

    &: hover {
        cursor: pointer;
    }
`

const Ul = styled.ul`
    padding-right: 10px;
    list-style-type: none;
`

const Li = styled.li`

    font-size: 1em;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`


const Menu = ({setIsAuthenticated}) => {
    const [menuOpen,setMenuOpen] = React.useState(false);
    const menuRef = useRef(null);

    const handleLogout = () => {
        Cookies.remove('csrf_access_token');
        Cookies.remove('access_token_cookie');
        setIsAuthenticated(false);
    }

    const openMenu = () => {
        setMenuOpen(true);
    }

    const closeMenu = () => {
        setMenuOpen(false);
    };


    React.useEffect(() => {
        
        const handleClickOutside = (e) => {
            // closeMenu();
            // console.log(e.target);
            // console.log("mr:",menuRef.current);
            // console.log("br:",buttonRef.current);
            // console.log("ur:",ulRef.current);

            if (menuRef.current && menuOpen && !menuRef.current.contains(e.target)) {
                console.log(e.target);
                // console.log("here");
                closeMenu();
            }
        }
        
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };

      },[menuOpen]);

    return(
        <MenuContainer $menuOpen={menuOpen} ref={menuRef} >
            <MenuButton onClick={openMenu} $menuOpen={menuOpen}>
                {'\u2630'}
            </MenuButton>
            
            {menuOpen && (
                <>
                    <Ul>
                        <Li onClick={handleLogout}>Logout</Li>
                    </Ul>
                </>
            )}
        </MenuContainer>
    );
}

export default Menu;