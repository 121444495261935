import React from 'react';
import { useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import { darkGray, sansSerif } from '../../Styles';
import { Input,InputRow, ButtonRow,BackButton,SubmitButton, AuthenticationActions, NameContainer,ImageContainer,Image } from './AuthStyles';
import { url } from '../../config';
import axios from 'axios';
import logo512 from '../../assets/logo512.png';

const PasswordRecoveryContainer = styled.div`
    display: flex;
    height: calc(var(--vh) * 100);
    align-items: center;
    justify-content: center;
`

const PasswordForm = styled.form`
    // width: 400px;
    // height: 400px;
    // border-radius: 20px;
    // box-shadow: 0px 0px 15px ${darkGray};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const SubtitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: ${sansSerif};
    font-size: 0.9em;
    padding-top: 10px;
    padding-bottom: 10px;
`

const PasswordRecovery = () => {
    let { jwt } = useParams();

    const [data,setData] = React.useState({
        password:""
    })
    const [headers,setHeaders] = React.useState({
        jwtValue:""
    });
    const [errorMessage,setErrorMessage] = React.useState(null);
    const [isSuccess,setIsSuccess] = React.useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData((prevInputData) => ({...prevInputData,[name]:value}));
    }

    React.useEffect(() => {
        setHeaders({
            jwtValue:jwt
        })
    },[jwt]);
    

    const resetPassword = React.useCallback( async () => {
        try {
            const response = await axios.post(url('reset-password'),data,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${jwt}`
                }
            });
            setIsSuccess(true);
        } catch (error) {
            console.log(error);
            // setErrorMessage(error.response.data);
        }
    },[data]);

    const handleSubmit = (e) => {
        e.preventDefault();
        resetPassword();
    }

    return (
        <PasswordRecoveryContainer>
            <AuthenticationActions>
                <NameContainer>Cogito Journal</NameContainer>
                <SubtitleContainer>Password reset</SubtitleContainer>
                <ImageContainer>
                    <Image src={logo512} />
                </ImageContainer>
                <PasswordForm onSubmit={handleSubmit}>
                    <InputRow>
                        <Input 
                            name = "password"
                            type="password"
                            value = {data.password}
                            onChange={handleInputChange}
                            placeholder='new password'
                        />
                    </InputRow>
                    <ButtonRow>
                        {/* <BackButton onClick={back}>Back</BackButton> */}
                        <SubmitButton type = "submit">Submit</SubmitButton>
                    </ButtonRow>
                    {/* {errorMessage} */}
                    
                        {isSuccess && (
                            <>
                               <p>Password successfully updated.</p><p><Link to={"/"}>Login</Link></p>
                            </>
                        )}
                    
                </PasswordForm>
            </AuthenticationActions>
        </PasswordRecoveryContainer>
    );
    

}

export default PasswordRecovery;