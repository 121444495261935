import './App.css';
import React from 'react';
import Authentication from './components/Authentication/Authentication';
import AppRoutes from './Routes';
import { Menu } from './components';


const App = () => {

  const [isAuthenticated,setIsAuthenticated] = React.useState(false);
  const [userId,setUserId] = React.useState(0);

    return (
      <div className='App'>
        <AppRoutes 
          isAuthenticated={isAuthenticated}
          setIsAuthenticated={setIsAuthenticated}
          userId={userId}
          setUserId={setUserId}
        />
        <Menu 
          setIsAuthenticated={setIsAuthenticated}
        />
      </div>
    );
}


export default App;
