import styled from 'styled-components';
import {serif,sansSerif,lightGray,primaryColor, fontColor} from '../../Styles';

const AuthenticationContainer = styled.div`
    display: flex;
    height: 100%;

    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
`

const AuthenticationContentContainer = styled.div`
    height: 500px;
    width: 400px;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: ${sansSerif};
    padding-left: 15px;
    padding-right: 15px;
`

const AuthenticationForm = styled.form`
    // height: 500px;
    // width: 400px;
    background-color: white;
    // border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: ${sansSerif};
    padding-left: 15px;
    padding-right: 15px;
`

const InputRow = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ButtonRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const Input = styled.input`
    height: 50px;
    width: 80%;
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 20px;
    border: 1px solid ${lightGray};   
`

const Button = styled.button`
    font-family: ${sansSerif};
    height: 50px;
    width: 125px;
    border-width: 1px;
    border-style: solid;
    border-color: ${lightGray};
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
`

const BackButton = styled(Button)`
    background-color: white;
    border-color: ${lightGray}};
`

const SubmitButton = styled(Button)`
    background-color: ${primaryColor};
    border-color: ${primaryColor};
`

const Message = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${fontColor};
    font-size: 0.9em;
    font-family: ${sansSerif};
    min-height: 50px;
`

const ErrorMessage = styled(Message)`    
    color: red;
`


const LogoContainer = styled.div`
    display: flex;
    // flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const NameContainer = styled.div`
    display: flex;
    justify-content: center;
    font-size: 1.6em;
    line-height: 1em;
    font-weight: bold;
    font-family: ${sansSerif};
    margin-top: 10px;
    margin-bottom: 10px;
`

const ImageContainer = styled.div`
    height: 100px;
    width: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
`

const ActionsContainer = styled.div`
    // height: 300px;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
`

const OptionContainer = styled.div`
    display: flex;
    // flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const LoginButton = styled(Button)`
    background-color: ${primaryColor};
    border-color: ${primaryColor};
`

const SignupButton = styled(Button)`
    background-color: white;
    border-color: ${lightGray};
`

const Image = styled.img`
    width: 100%;
    height: auto;
    border-radius: 10px;
`;


const AuthenticationActions = styled.div`
    flex: 1;
    // background-color: red;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const QuoteContainer = styled.div`
    flex: 1.5;
    // background-color: blue;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Quote = styled.div`
    text-align: left;
    width: 80%;
`

const BigQuote = styled(Quote)`
    // font-family: ${serif};
    font-family: Gloock;
    font-size: 3em;
    font-style: italic;
    text-align: left;
`

const Author = styled(Quote)`
    font-family: ${serif};
    font-size: 1.3em;
    text-align: left;
    margin-top: 25px;
`

const SubQuote = styled(Quote)`
    text-align: left;
    margin-top: 60px;
    font-size: 1.3em;
    font-family: ${serif};
`

export {AuthenticationContainer,
    AuthenticationContentContainer,
    AuthenticationForm,
    InputRow,
    ButtonRow,
    Input, 
    BackButton, 
    SubmitButton, 
    Message, 
    ErrorMessage,
    LogoContainer,
    NameContainer,
    ImageContainer,
    OptionContainer,
    LoginButton,
    SignupButton,
    Image,
    ActionsContainer,
    AuthenticationActions,
    QuoteContainer,
    BigQuote,
    Author,
    SubQuote
}