import styled from 'styled-components';
import { sansSerif,lightGray,darkGray, veryLightGray } from '../../Styles';
import React from 'react';

const ContextPromptContainer = styled.div`
    border-radius: 10px;
    background-color: white;
    position: absolute;
    height: 120px;
    width: 300px;
    top: ${props => props.$y}px;
    left: ${props => props.$x}px;
    // right: ${props => props.$x + 200}px;
    // bottom: ${props => props.$y + 100}px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 0px 15px ${darkGray};
`

const ContextButton = styled.button`
    width: 100%;
    height: 35px;
    text-align: left;
    border: none;
    background-color: white;

    font-family: ${sansSerif};
    &:hover {
        background-color: ${lightGray};
    } 
`

const OptionContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const Option = styled.button`
    display: flex;
    align-items: center;
    justify-content: start;
    height: 30px;
    border: none;
    background-color: ${veryLightGray};
    font-family: ${sansSerif};
    &:hover {
        background-color: ${lightGray};
    } 
`

const ContextPrompt = ({x,y,highlightedText,bodyText}) => {
    
    const [resultState,setResultState] = React.useState({
        visible: false,
        option1:"",
        option2:"",
        option3:""
    });

    const handleClick = () => {
        setResultState(
            {
                visible: true,
                option1:"Lorem ipsum dolor sit amet",
                option2:"Lorem ipsum dolor sit amet",
                option3:"Lorem ipsum dolor sit amet"
            }
        );
    }

    return(
        <ContextPromptContainer $x={x} $y={y}>
            {!resultState.visible && (
                <>
                    <ContextButton onClick={handleClick}>Rewrite highlighted text</ContextButton>
                    <ContextButton onClick={handleClick}>Suggest next sentence</ContextButton>
                </>
            )}
            {resultState.visible && (
                <OptionContainer>
                    <Option>{resultState.option1}</Option>
                    <Option>{resultState.option2}</Option>
                    <Option>{resultState.option3}</Option>
                </OptionContainer>
            )}
        </ContextPromptContainer>
    );
}

export default ContextPrompt;


