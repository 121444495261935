import React from 'react';
import { AuthenticationForm, InputRow, Input, ButtonRow, BackButton,SubmitButton, Message, ErrorMessage } from './AuthStyles';
import axios from 'axios';
import { apiUrl } from '../../config';
import {PulseLoader} from 'react-spinners';
import { primaryColor } from '../../Styles';

const SignupModal = ({back,setIsAuthenticated,setUserId}) => {

    const [error,setError] = React.useState(null);
    const [loading,setLoading] = React.useState(false);
    const [postData,setPostData] = React.useState({
        email:''
        ,password:''
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPostData({
            ...postData
            ,[name]: value
        });
    }

    const handleCreateUser = async (e) => {
        e.preventDefault();
        const url = apiUrl + "create-user";

        try {
            const response = await axios.post(url,postData);
            const authDate = new Date();
            localStorage.setItem('tokenExpiration',authDate.toLocaleDateString());
            localStorage.setItem('userId',response.data.user_id);
            setUserId(response.data.user_id)
            setIsAuthenticated(true);
        } catch (error) {
            setError(error.response.data.error);
        } finally {
            setLoading(false);
        }
    }

    const handleEnterKey = (e) => {
        if (e.key === 'Enter') {
            handleCreateUser(e);
        }
    }

    return(
        <AuthenticationForm 
            onSubmit={handleCreateUser}
            onKeyDown={handleEnterKey}
            >
            <InputRow>
                <Input 
                    name = "email"
                    value = {postData.email}
                    onChange={handleInputChange}
                    placeholder='email'
                />
            </InputRow>
            <InputRow>
            <Input 
                    name = "password"
                    value = {postData.password}
                    onChange={handleInputChange}
                    placeholder='password'
                    type="password"
                />
            </InputRow>
            <ButtonRow>
                <BackButton onClick={back}>Back</BackButton>
                <SubmitButton type = "submit">Submit</SubmitButton>
            </ButtonRow>
            {error && (
                <ErrorMessage>
                    {error}
                </ErrorMessage>
            )}
            {loading && (
                <Message>
                    <PulseLoader color={primaryColor} />
                </Message>
            )}
        </AuthenticationForm>
    );
}

export default SignupModal;